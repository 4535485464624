<template>
	<div class="seminaire_view">
		<h3 class="mt-1 mb-8">
			<v-icon
				large
				color="red"
			>
				mdi-youtube
			</v-icon>
			Formation commerciale
		</h3>
		<v-row>
			<v-col>
				<b>Le Hub</b>
				<br>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/gqXLGN3ozrs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
			<v-col>
				<b>Novadoc</b>
				<br>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/wFs8kFg6dTw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
			<v-col>
				<b>CDC électronique</b>
				<br>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/gN6srl2tIFw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<b>Site vitrine + Nowa</b>
				<br>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/UYiscSTcT3k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
			<v-col>
				<b>GMB + NovaSEO</b>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/ydjKg95vOuQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
			<v-col>
				<b>Novagenda</b>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/RjPfM1oXQ2w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<b>Novavocat</b>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/LNep8XkCc5o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
			<v-col>
				<b>Les differents types de sites</b>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/ajwdxAfl6n8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
			<v-col>

			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-btn 
					depressed 
					to="/"
				>
					Retour
				</v-btn>				
			</v-col>
		</v-row>
	</div>
</template>

<script>
	export default {
		name: 'SeminaireView',
		data: () => ({

		}),
		created: function() {

		},
		methods: {

		},
	};
</script>

<style lang="scss">
	.seminaire_view
	{
		.logo
		{
			width: 30px;
			text-align: left;
		}	
	}

</style>